<template>
    <div>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
     <v-card color="#373535" elevation="0">

       <v-row no-gutters>
         <v-col class="ml-7" cols="9">
         </v-col>
         <v-col class="mt-8" cols="2">
          <v-btn @click="closeDialog()" fab dark class="" color="#434242" elevation="0" small>
              <v-icon size="15" color="#ffffff" dark>$xMarkIcon</v-icon>
          </v-btn>
         </v-col>
       </v-row>

      <v-row no-gutters>
         <v-col>
             <div class="" >
      <v-col cols="12">
          <v-card class="d-flex justify-center mr-2" color="#373535" dark elevation="0">
              <v-list color="#373535">
              <span class="d-flex justify-center">
                <v-icon v-if="this.$store.state.partnerInfo.partnerGender =='Male'" class="ml-2" size="90"> $manIcon </v-icon>
                <v-icon v-if="this.$store.state.partnerInfo.partnerGender =='Female'" class="ml-2" size="90"> $womanIcon </v-icon>
              </span>
              <span class="cardMetric mt-5 d-flex justify-center">
                Hey, {{this.$store.state.partnerInfo.partnerName}} !
              </span>
              <div class="ml-4 mt-8">
              <!-- <span class="d-flex justify-center mt-5 cardTitle">
                  <v-chip small class="chipTitle d-flex justify-left mr-2" color="#545353">Gender</v-chip>
                  <v-chip small class="chipTitle d-flex justify-left mr-2" :color="setColor()">{{this.$store.state.partnerInfo.partnerGender}}</v-chip>
              </span> -->


              <v-list color="#373535">
                <div class="ml-5">
                    <v-chip small class="chipTitle" color="#545353">Mobile</v-chip>

                    <v-chip small class="ml-3 chipTitle" :color="setColor()">{{this.$store.state.partnerInfo.partnerMobile}}</v-chip>

                </div>
                <div class="mt-6 ml-5">

                    <v-chip small class="chipTitle" color="#545353">Community</v-chip>
                    <div class="mt-1">
                    <v-chip :key="community.communityName" v-for="community in communitiesList" small class="mt-1 chipTitle" :color="setColor()">{{community.communityName}}</v-chip>
                    </div>
                </div>

                <div class="mt-6 ml-5">
                    <v-chip small class="chipTitle" color="#545353">Brand Name</v-chip>
                    <v-chip small class="ml-3 chipTitle" :color="setColor()">{{this.$store.state.partnerInfo.partnerBrand}}</v-chip>
                </div>

             </v-list>

              </div>

              </v-list>
          </v-card>

      </v-col>

      </div>
         </v-col>
      </v-row>
      <v-row no-gutters>
       <v-col cols="3">
       </v-col>
       <v-col cols="6">
         <div class="mt-5 mr-4 ml-4">
         <v-btn @click="logout()" class="loginBtn" height="40px" small block text dark elevation="0">
           Logout
         </v-btn>
         </div>

         <div class="mt-8">
         <span class="footer d-flex justify-center">Made with<v-icon small class="mr-1 ml-1" color="red">mdi-cards-heart</v-icon> in Hyderabad </span>
         </div>
       </v-col>
       <v-col cols="3">
       </v-col>
     </v-row>
     </v-card>


    </v-dialog>
    </div>
</template>
<script>
import router from '@/router'
export default {
    data () {
      return {
        dialog: true,
        communitiesList: ''
      }
    },
    async mounted () {
      this.communitiesList = []
      for (var i=0; i<this.$store.state.partnerInfo.partnerCommunities.length; i++) {
        this.communitiesList.push({
          communityName: this.$store.state.partnerInfo.partnerCommunities[i].communityId.communityName
        })
      }
    },
    methods: {
        setColor () {
            if (this.$store.state.partnerInfo.partnerGender == 'Male') {
                return '#4B9C6D'
            } else {
                return '#F2BA55'
            }
        },
        closeDialog () {
            this.dialog = false,
            router.push('/')
        },
        logout () {
          this.$store.dispatch('setToken', null)
          this.$store.dispatch('setPartnerInfo', null)
          this.$router.push({
            name: 'login'
          })
        }
    }
}
</script>
<style scoped>
.cardMetric{
font-family: Montserrat;
font-style: normal;
font-weight: 900;
font-size: 18px;
color: #C7C6C6;
}
.chipTitle {
font-family: Lato;
font-style: normal;
font-weight: 900;
font-size: 12px;
color: #FFFFFF;
}
.loginBtn {
text-transform: unset !important;
background: #E14B4B;
font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 14px;
color: #FFFFFF;
opacity: 0.8;
}
.footer {
font-family: Lato;
font-style: italic;
font-weight: 900;
font-size: 10px;
color: #C7C6C6;
}
</style>
